<template>
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Editar {{ petPackageData?.name }} do {{ petPackageData?.pet?.name
                                }}</h3>
                        </div>
                        <!-- /.card-header -->
                        <form role="form" method="post" @submit.prevent="updatePetPackage"
                            enctype="multipart/form-data">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label>Petshop</label>
                                            <select-client v-model="form.client_id"
                                                :class="errors?.client_id ? 'form-control is-invalid' : 'form-control'" />
                                            <small class="text-danger" v-if="errors?.client_id">{{ errors.client_id[0]
                                                }}</small>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label>Pet</label>
                                            <p class="fake-input">{{ petPackageData?.pet?.name }}</p>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label>Pacote</label>
                                            <input type="text"
                                                :class="errors?.name ? 'form-control is-invalid' : 'form-control'"
                                                name="name" v-model="form.name" placeholder="Nome do pacote">
                                            <small class="text-danger" v-if="errors?.name">{{ errors.name[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                                <p class="h3">Validade</p>
                                <hr />
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <!-- text input -->
                                        <div class="form-group">
                                            <label>Data de início</label>
                                            <input-date v-model="form.start_date" name="start_date"
                                                :class="errors?.start_date ? 'form-control is-invalid' : 'form-control'" />
                                            <small class="text-danger" v-if="errors?.start_date">{{ errors.start_date[0]
                                                }}</small>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-12">
                                        <!-- text input -->
                                        <div class="form-group">
                                            <label>Data final</label>
                                            <input-date v-model="form.end_date" name="end_date"
                                                :class="errors?.end_date ? 'form-control is-invalid' : 'form-control'" />
                                            <small class="text-danger" v-if="errors?.end_date">{{ errors.end_date[0]
                                                }}</small>
                                        </div>
                                    </div>
                                </div>

                                <p class="h3">Pagamento</p>
                                <hr />
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group box-checkbox">
                                            <label class="pr-2">Pacote pago?</label>
                                            <input class="form-check-input" name="is_paid" v-model="form.is_paid"
                                                type="checkbox">
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group box-checkbox">
                                            <label class="pr-2">Pacote ativo?</label>
                                            <input class="form-check-input" name="active" v-model="form.active"
                                                type="checkbox">
                                        </div>
                                    </div>
                                </div>

                                <package-services :package-services="form.pet_package_services"
                                    :delete-package-service-route="deletePetPackageServiceRoute" />
                            </div>
                            <!-- /.card-body -->

                            <div class="card-footer">
                                <button type="submit" class="float-right btn btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
</template>

<script>
import SelectClient from './SelectClient.vue';
import moment from 'moment';
import InputDate from './InputDate.vue';

export default {
    components: {
        SelectClient,
        InputDate,
    },
    props: {
        updatePetPackageRoute: {
            type: String,
            required: true
        },
        petPackageId: {
            type: Number,
            required: true
        },
        getPetPackageRoute: {
            type: String,
            required: true
        },
        deletePetPackageServiceRoute: {
            type: String,
            required: true
        },
        backRoute: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            form: {
                name: '',
                start_date: '',
                end_date: '',
                is_paid: false,
                active: false,
                pet_package_services: [],
                pet_id: '',
            },
            petPackageData: {},
            errors: {},
        }
    },
    methods: {
        updatePetPackage() {
            window.$('.loader').fadeIn();
            window.$('button[type="submit"]').attr('disabled', true);
            const newPetPackageServices = this.form.pet_package_services.map(petPackageService => {
                return {
                    id: petPackageService.id,
                    pet_package_id: this.petPackageData.id,
                    service_id: petPackageService.service.id,
                    service: petPackageService.service,
                    unit_price: petPackageService.unit_price,
                    quantity: petPackageService.quantity
                }
            });
            this.form.pet_package_services = newPetPackageServices;
            axios.post(this.updatePetPackageRoute, this.form)
                .then(response => {
                    window.$('.loader').fadeOut();
                    window.$(document).Toasts('create', {
                        class: 'bg-success',
                        title: 'Sucesso',
                        body: response.data.message,
                    });
                    setTimeout(() => {
                        window.location.href = this.backRoute;
                    }, 1500);
                })
                .catch(error => {
                    window.$('.loader').fadeOut();

                    if (this.form.pet_package_services.length === 0) {
                        window.$(document).Toasts('create', {
                            class: 'bg-danger',
                            title: 'Erro',
                            body: 'O pacote precisa de serviços'
                        });
                    }
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else {
                        window.$(document).Toasts('create', {
                            class: 'bg-danger',
                            title: 'Erro',
                            body: error.response.data.message
                        });
                    }
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                });
        }
    },
    mounted() {
        window.$('.loader').fadeIn();
        axios.get(this.getPetPackageRoute + '?jsonType=true')
            .then(response => {
                this.form.name = response.data.name;
                this.form.is_paid = response.data.is_paid ? true : false;
                this.form.active = response.data.active ? true : false;
                this.form.start_date = response.data.start_date ? moment(response.data.start_date).format('DD/MM/YYYY') : '';
                this.form.end_date = response.data.end_date ? moment(response.data.end_date).format('DD/MM/YYYY') : '';
                this.form.client_id = response.data.client_id;
                this.form.pet_id = response.data.pet_id;

                const newPetPackageServices = response.data.pet_package_services.map(petPackageService => {
                    return {
                        id: petPackageService.id,
                        pet_package_id: this.petPackageData.id,
                        service: petPackageService.service,
                        unit_price: petPackageService.unit_price,
                        quantity: petPackageService.quantity
                    }
                });
                this.form.pet_package_services = newPetPackageServices;
                this.petPackageData = response.data;
                window.$('.loader').fadeOut();
            })
            .catch(_ => {
                window.$('.loader').fadeOut();
                window.$(document).Toasts('create', {
                    class: 'bg-danger',
                    title: 'Erro',
                    body: 'Erro ao buscar pacote do pet, entre em contato com suporte'
                });
            });
    }
}
</script>
