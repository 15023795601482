<template>
<input class="form-control" @change="$emit('update:modelValue', formattedValue)" placeholder="__/__/____" v-model="formattedValue" @input="onInput" @blur="onBlur" @focus="onFocus" />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    watch: {
        modelValue(val) {
            if (val === null || val === undefined || val === '') {
                this.formattedValue = '';
                this.rawValue = '';
                return;
            }
            this.formattedValue = val;
            this.rawValue = val;
        }
    },
    data() {
        return {
            rawValue: '',
            formattedValue: ''
        };
    },
    methods: {
        formatNumber(value) {
            let v = value.replace(/\D/g, '').slice(0, 8);
            if (v.length >= 5) {
                return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
            } else if (v.length >= 3) {
                return `${v.slice(0,2)}/${v.slice(2)}`;
            }
            return v;

        },
        onInput(event) {
            this.rawValue = event.target.value;
            this.formattedValue = this.formatNumber(this.rawValue);
        },
        onBlur() {
            this.formattedValue = this.formatNumber(this.rawValue);
        },
        onFocus() {
            this.formattedValue = this.rawValue;
        }
    }
};
</script>
