<template>
    <select name="client_id" v-model="selectedClientId" @change="changeService">
        <option value="">Selecione</option>
        <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.company_name }}</option>
    </select>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Object, Number],
            default: ''
        }
    },
    watch: {
        modelValue(val) {
            if (val === null || val === undefined || val === '') {
                this.selectedClientId = '';
                return;
            }
            this.selectedClientId = val;
        }
    },
    data() {
        return {
            selectedClientId: '',
            clients: [],
        };
    },
    methods: {
        changeService() {
            this.$emit('update:modelValue', this.selectedClientId);
        },
        async getClients() {
            window.$('.loader').fadeIn();
            const route = window.$('.client-route').val();

            axios.get(route + '?jsonType=true').then(response => {
                window.$('.loader').fadeOut();
                this.clients = response.data;
            }).catch(error => {
                window.$('.loader').fadeOut();
            });
        }
    },
    async mounted() {
        await this.getClients();
    }
};
</script>