<template>
    <div>
        <p class="h5 font-weight-bold">
            <i class="fas fa-concierge-bell"></i> Serviços do pacote
        </p>
        <hr />
        <div class="row d-flex align-items-center">
            <div class="col-md-4 col-sm-12 mb-3">
                <div class="form-group">
                    <label>Serviço</label>
                    <select-service v-model="service" :exludeServicesId="selectedServicesId" :class="errors?.service ? 'form-control is-invalid' : 'form-control'"/>
                </div>
                <small class="text-danger" v-if="errors?.service">{{
                    errors.service[0]
                }}</small>
            </div>

            <div class="col-md-4 col-sm-12 mb-3">
                <div class="form-group">
                    <label>Preço</label>
                    <input-money v-model="unit_price" name="unit_price" :class="errors?.unit_price ? 'is-invalid' : ''" />
                </div>
                <small class="text-danger" v-if="errors?.unit_price">{{ errors.unit_price[0]
                    }}</small>
            </div>

            <div class="col-md-3 col-sm-12 mb-3">
                <div class="form-group">
                    <label>Quantidade</label>
                    <input-number v-model="quantity" v-on:input="quantity = $event.target.value" name="quantity"
                        :class="errors?.quantity ? 'is-invalid' : ''" />
                </div>
                <small class="text-danger" v-if="errors?.quantity">{{ errors.quantity[0]
                    }}</small>
            </div>

            <div class="col-md-1 col-sm-12 mb-3">
                <div class="form-group">
                    <label>Adicionar</label>
                    <button type="button" @click="addService()" class="btn btn-primary text-center w-100">
                        <i class="fas fa-plus right"></i>
                    </button>
                </div>

                <small class="text-danger" v-if="errors?.message">{{ errors.message[0]
                    }}</small>
            </div>
        </div>

        <div class="p-0">
            <table class="table table-bordered table-hover">
                <thead class="bg-d2a-orange">
                    <tr class="text-d2a-blue">
                        <th>#</th>
                        <th>Serviço</th>
                        <th>Preço</th>
                        <th>Quantidade</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(packageService, index) in packageServices" :key="index">
                        <td>{{ packageService.key }}</td>
                        <td>{{ packageService.service.name }}</td>
                        <td>{{ packageService.unit_price }}</td>
                        <td>{{ packageService.quantity }}</td>
                        <td>
                            <button type="button" @click="removeService(index, packageService)"
                                class="text-center btn btn-danger w-100">
                                <i class="fas fa-minus right"></i>
                            </button>
                        </td>
                    </tr>
                    <tr rowspan="5">
                        <td colspan="5" class="text-center"><b>Total:</b> {{ getTotal() }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        packageServices: {
            type: Array,
            default: []
        },
        deletePackageServiceRoute: {
            type: String,
            default: '',
            required: true,
        }
    },
    data() {
        return {
            service: '',
            unit_price: '',
            quantity: '',
            errors: {},
            selectedServicesId: [],
        }
    },
    watch: {
        packageServices: {
            handler(val) {
                if (val.length > 0) {
                    this.selectedServicesId = val.map(packageService => packageService.service.id);
                }
            },
            immediate: true,
            deep: true,
        }
    },
    methods: {
        addService() {
            this.errors = {}

            if (!this.service) {
                this.errors.service = ['Serviço é obrigatório'];
            }

            if (!this.unit_price) {
                this.errors.unit_price = ['Preço é obrigatório'];
            }

            if (!this.quantity) {
                this.errors.quantity = ['Quantidade é obrigatório'];
            }

            if (Object.keys(this.errors).length > 0) {
                this.errors.message = ['Dados inválidos'];
                return;
            }

            this.packageServices.push({
                id: null,
                key: this.packageServices.length,
                service: this.service,
                unit_price: this.unit_price,
                quantity: this.quantity,
            });

            this.errors = {}
            this.service = '';
            this.unit_price = '';
            this.quantity = '';
        },
        async removeService(key, packageService) {
            if (packageService.id) {
                window.$('.loader').fadeIn();
                window.$('button').attr('disabled', true);
                axios.get(this.deletePackageServiceRoute + '?package_service_id=' + packageService.id).then(() => {
                    window.$('.loader').fadeOut();
                    window.$(document).Toasts('create', {
                        class: 'bg-success',
                        title: 'Sucesso',
                        body: 'Serviço removido com sucesso',
                    });
                    this.packageServices.splice(key, 1);
                }).catch(error => {
                    window.$('.loader').fadeOut();
                    window.$(document).Toasts('create', {
                        class: 'bg-danger',
                        title: 'Erro',
                        body: error.response.data.message,
                    });
                });

                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return;
            }
            this.packageServices.splice(key, 1);
        },
        getTotal() {
            let total = 0;
            this.packageServices.forEach(packageService => {
                total += parseFloat(packageService.unit_price.replace(',', '.')) * packageService.quantity;
            });
            return total;
        }
    }
}
</script>
