<template>
<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">
                            {{ packageId ? 'Editar' : 'Cadastrar' }} pacote
                        </h3>
                    </div>
                    <!-- /.card-header -->
                    <form role="form" method="post" @submit.prevent="upsertPackage" enctype="multipart/form-data">
                        <input type="hidden" name="id" v-model="form.id">

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Nome do pacote</label>
                                        <input type="text" :class="errors?.name ? 'form-control is-invalid' : 'form-control'" name="name" v-model="form.name" placeholder="Nome do pacote">
                                        <small class="text-danger" v-if="errors?.name">{{errors.name[0]}}</small>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Pet shop</label>
                                        <select-client v-model="form.client_id" :class="errors?.client_id ? 'form-control is-invalid' : 'form-control'" />
                                        <small class="text-danger" v-if="errors?.client_id">{{errors.client_id[0]}}</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <label>Descrição</label>
                                        <textarea :class="errors?.description ? 'form-control is-invalid' : 'form-control'" name="description" v-model="form.description" placeholder="Descrição"></textarea>
                                        <small class="text-danger" v-if="errors?.description">{{errors.description[0]}}</small>
                                    </div>
                                </div>
                            </div>

                            <package-services
                                :package-services="form.services"
                                :delete-package-service-route="deletePackageServiceRoute"
                            />
                        </div>
                        <!-- /.card-body -->

                        <div class="card-footer">
                            <button type="submit" class="float-right btn btn-primary">{{ packageId ? 'Salvar' : 'Cadastrar' }}</button>
                        </div>
                    </form>
                </div>
                <!-- /.card -->
            </div>
        </div>
        <!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<!-- /.content -->
</template>

<script>
import SelectClient from './SelectClient.vue';
export default {
    components: {
        SelectClient
    },
    props: {
        route: {
            type: String,
            required: true
        },
        backRoute: {
            type: String,
            required: true
        },
        packageId: {
            type: Number,
            default: null,
            required: false
        },
        getPackageRoute: {
            type: String,
            required: false
        },
        deletePackageServiceRoute: {
            type: String,
            default: '',
            required: true,
        }
    },
    data() {
        return {
            form: {
                id: null,
                name: '',
                description: '',
                services: [],
                client_id: '',
            },
            errors: {},
        }
    },
    methods: {
        async upsertPackage() {
            window.$('.loader').fadeIn();
            window.$('button[type="submit"]').attr('disabled', true);
            const newServices = this.form.services.map(service => {
                return {
                    id: service.id,
                    service_id: service.service.id,
                    unit_price: service.unit_price,
                    quantity: service.quantity
                }
            });
            this.form.services = newServices;
            axios.post(this.route, this.form).then(response => {
                window.$('.loader').fadeOut();
                window.$(document).Toasts('create', {
                    class: 'bg-success',
                    title: 'Sucesso',
                    body: response.data.message,
                });

                setTimeout(() => {
                    window.location.href = this.backRoute;
                }, 1500);
            }).catch(error => {
                window.$('.loader').fadeOut();
                this.errors = error.response.data.errors;

                if (this.errors?.services) {
                    window.$(document).Toasts('create', {
                        class: 'bg-danger',
                        title: 'Erro',
                        body: 'Cadastre ao menos um serviço'
                    });
                    window.$('button[type="submit"]').attr('disabled', false);
                    return;
                }

                if (error.response.status === 422) {
                    window.$(document).Toasts('create', {
                        class: 'bg-warning',
                        title: 'Alerta',
                        body: 'Verifique os campos obrigatórios'
                    });
                    window.$('button[type="submit"]').attr('disabled', false);
                    return;
                }

                window.$(document).Toasts('create', {
                    class: 'bg-danger',
                    title: 'Erro',
                    body: 'Erro ao cadastrar pacote, entre em contato com suporte'
                });
                
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            });
        }
    },
    mounted() {
        if (!this.packageId) {
            return;
        }
        window.$('.loader').fadeIn();
        axios.get(this.getPackageRoute).then(response => {
            window.$('.loader').fadeOut();
            this.form = response.data.package;
            this.form.services = response.data.package.package_services;
        }).catch(error => {
            window.$('.loader').fadeOut();
            window.$(document).Toasts('create', {
                class: 'bg-danger',
                title: 'Erro',
                body: 'Erro ao buscar pacote, entre em contato com suporte'
            });
        });
    }
}
</script>
