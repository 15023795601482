<template>
    <input class="form-control" @change="$emit('update:modelValue', formattedValue)" placeholder="000"
        v-model="formattedValue" @input="onInput" @blur="onBlur" @focus="onFocus" />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    watch: {
        modelValue(val) {
            if (val === null || val === undefined || val === '') {
                this.formattedValue = '';
                this.rawValue = '';
            }
        }
    },
    data() {
        return {
            rawValue: '',
            formattedValue: ''
        };
    },
    methods: {
        formatNumber(value) {
            value = value.replace(/[^\d{}]/g, '');
            return value;
        },
        onInput(event) {
            this.rawValue = event.target.value;
            this.formattedValue = this.formatNumber(this.rawValue);
        },
        onBlur() {
            this.formattedValue = this.formatNumber(this.rawValue);
        },
        onFocus() {
            this.formattedValue = this.rawValue;
        }
    }
};
</script>